// src/db.js
import { openDB } from 'idb';

const DB_NAME = 'nespressoDB';
const DB_VERSION = 1;
const STORE_NAME = 'participations';

export async function initDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    },
  });
}

export async function saveParticipation(participation) {
  const db = await initDB();
  await db.add(STORE_NAME, participation);
}

export async function getParticipations() {
  const db = await initDB();
  return await db.getAll(STORE_NAME);
}
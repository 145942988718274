// src/SlotMachine.js
import React, { useState } from "react";
import { saveParticipation } from "./db";
import "./App.css";

const items = [
  { id: 1, name: "Baloncesto", icon: "🏀" },
  { id: 2, name: "Castillo", icon: "🏰" },
  { id: 3, name: "Galleta", icon: "🍪" },
];

function SlotMachine() {
  const [reel1, setReel1] = useState([]);
  const [reel2, setReel2] = useState([]);
  const [reel3, setReel3] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [userData, setUserData] = useState({ name: "", email: "" });

  const generateCombination = () => {
    setIsSpinning(true);
    setShowForm(false);
    setTimeout(() => {
      const newReel1 = items[Math.floor(Math.random() * items.length)];
      setReel1(newReel1);
    }, 1000);

    setTimeout(() => {
      const newReel2 = items[Math.floor(Math.random() * items.length)];
      setReel2(newReel2);
    }, 2000);

    setTimeout(() => {
      const newReel3 = items[Math.floor(Math.random() * items.length)];
      setReel3(newReel3);
      setIsSpinning(false);
    }, 3000);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const combination = `${reel1.name}, ${reel2.name}, ${reel3.name}`;
    const participation = {
      name: userData.name,
      email: userData.email,
      combination: combination,
      date: new Date().toISOString(),
    };

    await saveParticipation(participation);
    alert("¡Participación registrada con éxito!");
    setUserData({ name: "", email: "" });
    setShowForm(false);
  };

  return (
    <div className="slot-machine-container">
      <h1 className="title">🎰 Nespresso Premiere 🎰</h1>
      <p className="subtitle">¿Te lo puedes imaginar? Si necesitas inspiración, acciona la palanca</p>

      <div className="slot-machine">
        <div className="reels">
          <div className={`reel ${isSpinning ? "spinning" : ""}`}>
            <div className="reel-cylinder">
              <div className="reel-item">{reel1.icon || "❓"}</div>
            </div>
          </div>
          <div className={`reel ${isSpinning ? "spinning" : ""}`}>
            <div className="reel-cylinder">
              <div className="reel-item">{reel2.icon || "❓"}</div>
            </div>
          </div>
          <div className={`reel ${isSpinning ? "spinning" : ""}`}>
            <div className="reel-cylinder">
              <div className="reel-item">{reel3.icon || "❓"}</div>
            </div>
          </div>
        </div>

        <div className="lever" onClick={generateCombination}>
          <div className={`lever-bar ${isSpinning ? "pulled" : ""}`}>
            <div className="lever-knob"></div>
          </div>
        </div>
      </div>

      {reel1.icon && reel2.icon && reel3.icon && (
        <div className="result-box">
          <p className="result-text">{`¿Un ${reel1.name}, en un ${reel2.name}, haciendo ${reel3.name}?`}</p>
          <button className="submit-button" onClick={() => setShowForm(true)}>
            Podría ser
          </button>
        </div>
      )}

      {showForm && (
        <form className="user-form" onSubmit={handleFormSubmit}>
          <h2>Registra tu Participación</h2>
          <label>
            Nombre:
            <input
              type="text"
              value={userData.name}
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              required
            />
          </label>
          <button type="submit" className="submit-button">Enviar</button>
        </form>
      )}
    </div>
  );
}

export default SlotMachine;
// src/AdminPage.js
import React, { useEffect, useState } from "react";
import { getParticipations } from "./db";

function AdminPage() {
  const [participations, setParticipations] = useState([]);

  useEffect(() => {
    // Obtener participaciones de IndexedDB al cargar la página
    async function fetchParticipations() {
      const storedParticipations = await getParticipations();
      setParticipations(storedParticipations);
    }
    fetchParticipations();
  }, []);

  return (
    <div className="admin-container">
      <h2>Administración - Participaciones</h2>
      <ul className="participations-list">
        {participations.map((participation) => (
          <li key={participation.id}>
            <strong>Fecha:</strong> {new Date(participation.date).toLocaleString()} <br />
            <strong>Nombre:</strong> {participation.name} <br />
            <strong>Email:</strong> {participation.email} <br />
            <strong>Combinación:</strong> {participation.combination}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminPage;
// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import SlotMachine from "./SlotMachine";
import AdminPage from "./AdminPage";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="container">
        <nav>
          <Link to="/" className="nav-link">Inicio</Link>
          <Link to="/admin" className="nav-link">Administración</Link>
        </nav>
        <Routes>
          <Route path="/" element={<SlotMachine />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;